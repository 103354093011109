import React, { useContext, useEffect, useRef } from 'react';
import { AppContext } from '@/store/context';
import { CaseStudyData } from '@/mock/SiteData';
import SEO from '@/components/shared/SearchEngineOptimizations/SearchEngineOptimizations';
import useTriggerLightTheme from '@/hooks/useTriggerLightTheme';
import NotFoundPage from '@/pages/404';
import { CaseStudyBanner } from '@/components/shared/case-study-banner';
import { TitleCopy } from '@/components/shared/title-copy';
import useScrollAnimation from '@/hooks/useScrollAnimation';
import { ImageGallery } from '@/components/shared/image-gallery';
import { cn } from '@/lib/utils';
import { Separator } from '@/components/ui/separator';
import { TechnologiesList } from '@/components/shared/technologies-list';
import GatsbyLogo from '@/assets/images/technologies/gatsby.webp';
import { AnimatedTagCopy } from '@/components/shared/animated-tag-copy';
import { useTransitionAnimation } from '@/hooks/useTransitionAnimation';

const ANIMATED_CLASSNAME = 'animated fadeInUp';

const caseStudy = CaseStudyData.find((m) => m.id === 'nytimes');

const NyTimes = () => {
  const { setCurrentPage } = useContext(AppContext);

  const { tlState, defaultAnimationOffset } = useTransitionAnimation();

  const projectWhiteSectionRef = useRef<HTMLDivElement>(null);

  useTriggerLightTheme(
    projectWhiteSectionRef.current,
    defaultAnimationOffset,
    defaultAnimationOffset,
    tlState,
    0.5,
  );

  // TODO: we'll to think a better approach to avoid calling several times the same webhook
  const titleCopyFirst = useScrollAnimation();

  const titleCopySecond = useScrollAnimation();

  const titleCopyThird = useScrollAnimation();

  const imageGalleryFirst = useScrollAnimation();

  const imageGallerySecond = useScrollAnimation();

  const technologiesListRef = useScrollAnimation();

  const lightSeparatorRef = useScrollAnimation();

  const darkSeparatorRef = useScrollAnimation();

  useEffect(() => {
    setCurrentPage('Case Study');
  }, []);

  if (!caseStudy) {
    return <NotFoundPage />;
  }

  const {
    hero: { title, heroDescriptionText, bgImg, projectSection },
    pageDescription,
    externalLink,
  } = caseStudy || {};

  return (
    <main className="page page--case-study">
      <CaseStudyBanner
        pageDescription={pageDescription}
        descriptionText={heroDescriptionText}
        title={title}
        bgImg={bgImg}
        projectSection={projectSection}
        paralaxTranslateY={[20, -20]}
        externalLink={externalLink}
      />
      <div className="case-study-content">
        <div ref={projectWhiteSectionRef} className="project-white-section">
          <div className="flex flex-col gap-20 bg-white py-20 lg:gap-36 lg:py-36">
            <TitleCopy
              ref={titleCopyFirst}
              title="The Client"
              description="The New York Times is one of the most prestigious newspapers in North America and maybe in the World with more than 9.17 Million paid subscribers."
              className={ANIMATED_CLASSNAME}
            />
            <ImageGallery
              ref={imageGalleryFirst}
              className={cn('px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
              images={[
                { src: '/images/case-studies/nytimes/mock-1.webp', alt: 'image1' },
                { src: '/images/case-studies/nytimes/mock-2.webp', alt: 'image2' },
              ]}
            />
            <TitleCopy
              ref={titleCopySecond}
              title="The Need"
              description={`As it's well known one of the biggest revenue streams for Newspapers is advertising, things were easier early on when you had just one advertising format, now with the rise of digital the different types of advertising has grown exponentially.\n\nSo the NYT needed a site to showcase all the different types of advertising they offer so companies could pick the right one for them.`}
              className={ANIMATED_CLASSNAME}
            />
            <ImageGallery
              ref={imageGallerySecond}
              className={cn('px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
              images={[
                { src: '/images/case-studies/nytimes/new-york-times-scrolling.gif', alt: 'gif1' },
              ]}
            />
            <TitleCopy
              ref={titleCopyThird}
              title="The Solution"
              description={`We work side-by-side with the Fictive Kin and The New York Times design team to create a smooth experience focused on performance.\n\nAfter exploring a few options we knew that GatsbyJS was the way to go, a lightning-fast experience that has an exceptional SEO ranking and all the benefits of react but with server-side rendering.\n\nBeing able to manage the content in real-time is a new standard nowadays, so we integrated the FrontEnd with Contentful CMS to give the client an easy way to manage the content behind the scenes.`}
              className={ANIMATED_CLASSNAME}
            />
            <Separator
              ref={lightSeparatorRef}
              className={cn('mx-auto max-w-7xl w-[95%] lg:-mb-36', ANIMATED_CLASSNAME)}
            />
          </div>
        </div>
        <div className=" animate-transition flex flex-col gap-36 bg-loop-black lg:py-36">
          <TechnologiesList
            ref={technologiesListRef}
            icons={[
              {
                icon: <img className="w-40" src={GatsbyLogo} key="gatsby" alt="gatsby" />,
                id: 'gatsby',
              },
            ]}
            description=""
            className={{
              section: cn(ANIMATED_CLASSNAME, 'lg:items-center'),
              text: 'animate-transition-text text-white',
            }}
          />
          <div className="block">
            <AnimatedTagCopy
              tag="the result"
              className={{
                section: cn('mx-auto px-4 md:px-8 xl:px-0'),
                tag: 'animate-transition-text',
              }}
              animatedCopy={{
                id: 'animated-copy-1',
                text: '<w>/<w> We delivered a website that, in addition to its <w>refinement<w> and <w>simplicity<w>, reinforces the values and concepts of the brand.',
                revealDelay: 0.15,
                animationStart: 'top 40%',
                className: 'mx-auto',
              }}
            />
            <AnimatedTagCopy
              className={{ section: 'mx-auto mt-12 px-4 md:px-8 xl:px-0' }}
              animatedCopy={{
                id: 'animated-copy-2',
                text: 'Easy to access, <w>dynamic<w> and <w>agile<w>, a website that met the needs of the client and that its users will enjoy browsing. Go ahead! We invite you to discover a site with a <w>narrative<w> like no other.',
                revealDelay: 0.75,
                animationStart: 'top 40%',
                className: 'mx-auto',
              }}
            />
          </div>
          <Separator
            ref={darkSeparatorRef}
            className={cn('mx-auto max-w-[1064px] bg-gray-dark', ANIMATED_CLASSNAME)}
          />
        </div>
      </div>
    </main>
  );
};

export default NyTimes;

export const Head = () => {
  if (caseStudy) {
    return (
      <SEO
        title="Loop3 Studio - New York Times"
        description={`${caseStudy.hero.heroDescriptionText}`}
      />
    );
  }
};
